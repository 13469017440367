import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import AppLayout from "src/layouts/AppLayout";
import Dashboard from "./Dashboard";
import Regex from "./Regex";
import BankHeadList from "./bank/BankHeadList";
import ViewBankHead from "./bank/ViewBankHead";
import BillList from "./bill/BillList";
import ChartView from "./chart/ChartView";
import CreditnoteList from "./creditnote/CreditnoteList";
import ViewCreditnote from "./creditnote/ViewCreditnote";
import CustomerList from "./customer/CustomerList";
import ViewCustomerHead from "./customer/ViewCustomerHead";
import DebitorHeadList from "./debitor/DebitorHeadList";
import ViewDebitorHead from "./debitor/ViewDebitorHead";
import Employee2List from "./employee/Employee2List";
import ViewEmployee2 from "./employee/ViewEmployee2";
import FinanceInsuranceList from "./financeInsurance/FinanceInsuranceList";
import FinanceNonInsurance from "./financeInsurance/FinanceNonInsurance";
import ViewFinanceInsurance from "./financeInsurance/ViewFinanceInsurance";
import FinanceOtherWorksheetList from "./financeOtherWorksheet/FinanceOtherWorksheetList";
import ViewFinanceOtherWorksheet from "./financeOtherWorksheet/ViewFinanceOtherWorksheet";
import FinanceNonReceivable from "./financeReceivable/FinanceNonReceivable";
import FinanceReceivableList from "./financeReceivable/FinanceReceivableList";
import ViewFinanceReceivable from "./financeReceivable/ViewFinanceReceivable";
import FinanceNonReciveableFromRangRad from "./financeReciveableFromRangRad/FinanceNonReciveableFromRangRad";
import FinanceReciveableFromRangRadList from "./financeReciveableFromRangRad/FinanceReciveableFromRangRadList";
import ViewFinanceReciveableFromRangRad from "./financeReciveableFromRangRad/ViewFinanceReciveableFromRangRad";
import TAssetmovementList from "./movement/TAssetmovementList";
import ViewTAssetmovement from "./movement/ViewTAssetmovement";
import BsProvinceHList from "./province/BsProvinceHList";
import ViewBsProvinceH from "./province/ViewBsProvinceH";
import ReferenceList from "./reference/ReferenceList";
import ViewReference from "./reference/ViewReference";
import TRengRadWangBillList from "./rengradWangbill/TRengRadWangBillList";
import ViewTRengRadWangBill from "./rengradWangbill/ViewTRengRadWangBill";
import ReportWorksheetFlowList from "./reportWorksheetFlow/ReportWorksheetFlowList";
import ViewWorkSheetSteelHead from "./steel/ViewWorkSheetSteelHead";
import WorkSheetSteelHeadList from "./steel/WorkSheetSteelHeadList";
import SteelDischargeList from "./steelDischarge/SteelDischargeList";
import ViewSteelDischarge from "./steelDischarge/ViewSteelDischarge";
import TCancelreserveHeadList from "./tCancelreserveHead/TCancelreserveHeadList";
import ViewTCancelreserveHead from "./tCancelreserveHead/ViewTCancelreserveHead";
import TConfirmreserveHeadList from "./tConfirmreserve/TConfirmreserveHeadList";
import ViewTConfirmreserveHead from "./tConfirmreserve/ViewTConfirmreserveHead";
import TAssetHeadList from "./tasset/TAssetHeadList";
import ViewTAssetHead from "./tasset/ViewTAssetHead";
import TaxInvoiceList from "./taxInvoice/TaxInvoiceList";
import ViewTaxInvoice from "./taxInvoice/ViewTaxInvoice";
import TruckDischargeList from "./truckDischarge/TruckDischargeList";
import TruckUnDischargeList from "./truckDischarge/TruckUnDischargeList";
import TruckRepairList from "./truckRepair/TruckRepairList";
import TrushMoneyList from "./trushMoney/TrushMoneyList";
import ViewTrushMoney from "./trushMoney/ViewTrushMoney";
import TrushMoneyConfirmList from "./trushMoneyConfirm/TrushMoneyConfirmList";
import ViewTrushMoneyConfirm from "./trushMoneyConfirm/ViewTrushMoneyConfirm";
import TServiceHeadList from "./tservice/TServiceHeadList";
import ViewTServiceHead from "./tservice/ViewTServiceHead";
import WangBillReform from "./wangBillReform/WangBillReform";
import WorksheetAdvanceDateList from "./worksheetAdvanceDate/WorksheetAdvanceDateList";
import ViewWorksheetReturn from "./worksheetReturn/ViewWorksheetReturn";
import WorksheetReturnList from "./worksheetReturn/WorksheetReturnList";
import ConcludeWorksheetList from "./concludeWorksheet/ConcludeWorksheetList";
import ViewConcludeWorksheet from "./concludeWorksheet/ViewConcludeWorksheet";
import ReturnFinancePaymentList from "./returnFinancePayment/ReturnFinancePaymentList";
import ViewReturnFinancePayment from "./returnFinancePayment/ViewReturnFinancePayment";
import ReturnForWitholdingTaxList from "./returnForWitholdingTax/ReturnForWitholdingTaxList";
import WorksheetDateWorkReport from "./reports/WorksheetDateWorkReport";
import CustomerServiceUseReport from "./reports/CustomerServiceUseReport";
import TruckIncomeReport from "./reports/TruckIncomeReport";
import DebitorReport from "./reports/DebitorReport";
import RangradArrearsReport from "./reports/RangradArrearsReport";
import RangradSendFnReport from "./reports/RangradSendFnReport";
import MovementDebitNoteReport from "./reports/MovementDebitNoteReport";
import RangradDebitNoteReport from "./reports/RangradDebitNoteReport";
import FnReceiveDailySummaryReport from "./reports/FnReceiveDailySummaryReport";
import ChequeReport from "./reports/ChequeReport";
import PayinReport from "./reports/PayinReport";
import ChequePayReport from "./reports/ChequePayReport";
import PayinPayReport from "./reports/PayinPayReport";
import TrushMoneyReport from "./reports/TrushMoneyReport";
import WorksheetPaymentReport from "./reports/WorksheetPaymentReport";

const AppRoute: React.FC<RouteComponentProps> = (props) => {
  return (
    <AppLayout>
      <Switch>
        {/* dashboard */}
        <Route exact path="/app/dashboard" component={Dashboard} />

        {/* province */}
        <Route exact path="/app/bsProvinceH/list" component={BsProvinceHList} />
        <Route exact path="/app/bsProvinceH/view/:iid" component={ViewBsProvinceH} />
        <Redirect path="/app/bsProvinceH" to="/app/bsProvinceH/list" />

        {/* bankHead */}
        <Route exact path="/app/bankHead/list" component={BankHeadList} />
        <Route exact path="/app/bankHead/view/:ibankCode" component={ViewBankHead} />
        <Redirect path="/app/bankHead" to="/app/bankHead/list" />

        {/* reference */}
        <Route exact path="/app/reference/list" component={ReferenceList} />
        <Route exact path="/app/reference/view/:id" component={ViewReference} />
        <Redirect path="/app/reference" to="/app/reference/list" />

        {/* chart */}
        <Route exact path="/app/chart/chartview" component={ChartView} />
        <Redirect path="/app/chart" to="/app/chart/chartview" />

        {/* TruckRepair */}
        <Route exact path="/app/truckrepair/list" component={TruckRepairList} />
        <Redirect path="/app/truckrepair" to="/app/truckrepair/list" />

        {/* bill */}
        <Route exact path="/app/bill/list" component={BillList} />
        <Redirect path="/app/bill" to="/app/bill/list" />

        {/* �Ѻ���Шҡ㺧ҹʴ */}
        <Route exact path="/app/financeReceivable/list" component={FinanceReceivableList} />
        <Route exact path="/app/financeReceivable/view/:financeHead2Id" component={ViewFinanceReceivable} />
        <Route exact path="/app/financeReceivable/create" component={FinanceNonReceivable} />
        <Redirect path="/app/financeReceivable" to="/app/financeReceivable/list" />

        {/* �Ѻ���Шҡ��¡������ */}
        <Route exact path="/app/financeOtherWorksheet/list" component={FinanceOtherWorksheetList} />
        <Route exact path="/app/financeOtherWorksheet/view/:tOtherpaymentHeadId" component={ViewFinanceOtherWorksheet} />
        <Redirect path="/app/financeOtherWorksheet" to="/app/financeOtherWorksheet/list" />

        {/* �Ѻ�����Թ��Сѹ */}
        <Route exact path="/app/financeInsurance/list" component={FinanceInsuranceList} />
        <Route exact path="/app/financeInsurance/view/:tworksheetInsuranceId" component={ViewFinanceInsurance} />
        <Route exact path="/app/financeInsurance/create" component={FinanceNonInsurance} />
        <Redirect path="/app/financeInsurance" to="/app/financeInsurance/list" />

        {/* �Ѻ�����Թ����Ѵ */}
        <Route exact path="/app/financeReciveableFromRangRad/list" component={FinanceReciveableFromRangRadList} />
        <Route exact path="/app/financeReciveableFromRangRad/view/:idebitnoteNo" component={ViewFinanceReciveableFromRangRad} />
        <Route exact path="/app/financeReciveableFromRangRad/create" component={FinanceNonReciveableFromRangRad} />
        <Redirect path="/app/financeReciveableFromRangRad" to="/app/financeReciveableFromRangRad/list" />

        {/* �͡㺡ӡѺ���� */}
        <Route exact path="/app/taxInvoice/list" component={TaxInvoiceList} />
        <Route exact path="/app/taxInvoice/view/:id" component={ViewTaxInvoice} />
        <Redirect path="/app/taxInvoice" to="/app/taxInvoice/list" />

        {/* Ŵ˹�� */}
        <Route exact path="/app/creditNote/list" component={CreditnoteList} />
        <Route exact path="/app/creditNote/view/:id" component={ViewCreditnote} />
        <Redirect path="/app/creditNote" to="/app/creditNote/list" />

        {/* �Ѵ��㺧ҹ */}
        <Route exact path="/app/concludeWorksheet/list" component={ConcludeWorksheetList} />
        <Route exact path="/app/concludeWorksheet/view/:worksheetcode" component={ViewConcludeWorksheet} />
        <Redirect path="/app/concludeWorksheet" to="/app/concludeWorksheet/list" />

        {/* ¡��ԡ����Ѻ�Թ */}
        <Route exact path="/app/returnFinancePayment/list" component={ReturnFinancePaymentList} />
        <Route exact path="/app/returnFinancePayment/view/:code" component={ViewReturnFinancePayment} />
        <Redirect path="/app/returnFinancePayment" to="/app/returnFinancePayment/list" />

        {/* ¡��ԡ��â��ѡ � ������ */}
        <Route exact path="/app/returnForWitholdingTax/list" component={ReturnForWitholdingTaxList} />
        <Redirect path="/app/returnForWitholdingTax" to="/app/returnForWitholdingTax/list" />

        {/* �ҡ�Թ��Ҹ�Ҥ�� */}
        <Route exact path="/app/trushMoney/list" component={TrushMoneyList} />
        <Route exact path="/app/trushMoney/view/:id" component={ViewTrushMoney} />
        <Redirect path="/app/trushMoney" to="/app/trushMoney/list" />

        {/* �׹�ѹ��ýҡ�Թ */}
        <Route exact path="/app/trushMoneyConfirm/list" component={TrushMoneyConfirmList} />
        <Route exact path="/app/trushMoneyConfirm/view/:id" component={ViewTrushMoneyConfirm} />
        <Redirect path="/app/trushMoneyConfirm" to="/app/trushMoneyConfirm/list" />

        {/* �Ѻ���觧ҹ��Ѻ */}
        <Route exact path="/app/worksheetReturn/list" component={WorksheetReturnList} />
        <Route exact path="/app/worksheetReturn/view/:returnNo" component={ViewWorksheetReturn} />
        <Redirect path="/app/worksheetReturn" to="/app/worksheetReturn/list" />

        {/* ����Ѵ�͡���˹�� */}
        <Route exact path="/app/rengradWangbill/list" component={TRengRadWangBillList} />
        <Route exact path="/app/rengradWangbill/view/:debitnoteNo" component={ViewTRengRadWangBill} />
        <Redirect path="/app/rengradWangbill" to="/app/rengradWangbill/list" />

        {/*��Ѻ��ا���˹�� */}
        <Route exact path="/app/wangBillReform/view" component={WangBillReform} />
        <Redirect path="/app/wangBillReform" to="/app/wangBillReform/view" />

        {/* customer */}
        <Route exact path="/app/customer/list" component={CustomerList} />
        <Route exact path="/app/customer/view/:icustCode" component={ViewCustomerHead} />
        <Redirect path="/app/customer" to="/app/customer/list" />

        {/* debitor */}
        <Route exact path="/app/debitor/list" component={DebitorHeadList} />
        <Route exact path="/app/debitor/view/:iid" component={ViewDebitorHead} />
        <Redirect path="/app/debitor" to="/app/debitor/list" />

        {/* employee */}
        <Route exact path="/app/employee/list" component={Employee2List} />
        <Route exact path="/app/employee/view/:id" component={ViewEmployee2} />
        <Redirect path="/app/employee" to="/app/employee/list" />

        {/* TServiceHead */}
        <Route exact path="/app/tservice/list" component={TServiceHeadList} />
        <Route exact path="/app/tservice/view/:iserviceCode" component={ViewTServiceHead} />
        <Redirect path="/app/tservice" to="/app/tservice/list" />

        { /* TAassetHead */}
        <Route exact path="/app/tasset/list" component={TAssetHeadList} />
        <Route exact path="/app/tasset/view/:id" component={ViewTAssetHead} />
        <Redirect path="/app/tasset" to="/app/tasset/list" />

        {/* tAssetmovement */}
        <Route exact path="/app/tAssetmovement/list" component={TAssetmovementList} />
        <Route exact path="/app/tAssetmovement/view/:seq" component={ViewTAssetmovement} />
        <Redirect path="/app/tAssetmovement" to="/app/tAssetmovement/list" />

        {/* worksheetAdvanceDate */}
        <Route exact path="/app/worksheetAdvanceDate/list" component={WorksheetAdvanceDateList} />
        <Redirect path="/app/worksheetAdvanceDate" to="/app/worksheetAdvanceDate/list" />

        {/* reportWorksheetFlow */}
        <Route exact path="/app/reportWorksheetFlow/list" component={ReportWorksheetFlowList} />
        <Redirect path="/app/reportWorksheetFlow" to="/app/reportWorksheetFlow/list" />

        {/* truckDischarge */}
        <Route exact path="/app/truckDischarge/list" component={TruckDischargeList} />
        <Route exact path="/app/truckDischarge/truckUnDischargeList" component={TruckUnDischargeList} />
        <Redirect path="/app/truckDischarge" to="/app/truckDischarge/list" />

        {/* workSheetSteel */}
        <Route exact path="/app/workSheetSteel/list" component={WorkSheetSteelHeadList} />
        <Route exact path="/app/workSheetSteel/view/:ireserveCode" component={ViewWorkSheetSteelHead} />
        <Redirect path="/app/workSheetSteel" to="/app/workSheetSteel/list" />

        {/* steelDischarge */}
        <Route exact path="/app/steelDischarge/list" component={SteelDischargeList} />
        <Route exact path="/app/steelDischarge/view/:ireserveCode" component={ViewSteelDischarge} />
        <Redirect path="/app/steelDischarge" to="/app/steelDischarge/list" />

        {/* tConfirmreserveHead */}
        <Route exact path="/app/tConfirmreserveHead/list" component={TConfirmreserveHeadList} />
        <Route exact path="/app/tConfirmreserveHead/view" component={ViewTConfirmreserveHead} />
        <Redirect path="/app/tConfirmreserveHead" to="/app/tConfirmreserveHead/list" />

        {/* tCancelreserveHead */}
        <Route exact path="/app/tCancelreserveHead/list" component={TCancelreserveHeadList} />
        <Route exact path="/app/tCancelreserveHead/view" component={ViewTCancelreserveHead} />
        <Redirect path="/app/tCancelreserveHead" to="/app/tCancelreserveHead/list" />


        {/* start report */}
        {/* ��§ҹ������ԡ���١��� */}
        <Route exact path="/app/customerServiceUseReport/list" component={CustomerServiceUseReport} />
        <Redirect path="/app/customerServiceUseReport" to="/app/customerServiceUseReport/list" />

        {/* ��§ҹ��û�Ժѵԧҹ��Ш��ѹ */}
        <Route exact path="/app/worksheetDateWorkReport/list" component={WorksheetDateWorkReport} />
        <Redirect path="/app/worksheetDateWorkReport" to="/app/worksheetDateWorkReport/list" />
        {/* end report */}

        {/* ��§ҹ�����ö */}
        <Route exact path="/app/truckIncomeReport/list" component={TruckIncomeReport} />
        <Redirect path="/app/truckIncomeReport" to="/app/truckIncomeReport/list" />
        {/* end report */}

        {/* ��§ҹ�������١˹�� */}
        <Route exact path="/app/debitorReport/list" component={DebitorReport} />
        <Redirect path="/app/debitorReport" to="/app/debitorReport/list" />
        {/* end report */}

        {/* ��§ҹ����Ѵ��ҧ���Թ */}
        <Route exact path="/app/rangradArrearsReport/list" component={RangradArrearsReport} />
        <Redirect path="/app/rangradArrearsReport" to="/app/rangradArrearsReport/list" />
        {/* end report */}

        {/* ��§ҹ����Ѵ���Թ������Թ */}
        <Route exact path="/app/rangradSendFnReport/list" component={RangradSendFnReport} />
        <Redirect path="/app/rangradSendFnReport" to="/app/rangradSendFnReport/list" />
        {/* end report */}

        {/* ��§ҹ��������͹������˹��  */}
        <Route exact path="/app/movementDebitNoteReport/list" component={MovementDebitNoteReport} />
        <Redirect path="/app/movementDebitNoteReport" to="/app/movementDebitNoteReport/list" />
        {/* end report */}

        {/* ��§ҹ����Ѵ�͡���˹��  */}
        <Route exact path="/app/rangradDebitNoteReport/list" component={RangradDebitNoteReport} />
        <Redirect path="/app/rangradDebitNoteReport" to="/app/rangradDebitNoteReport/list" />
        {/* end report */}

        {/* ��§ҹ��ػ����Ѻ�Թ��Ш��ѹ  */}
        <Route exact path="/app/fnReceiveDailySummaryReport/list" component={FnReceiveDailySummaryReport} />
        <Redirect path="/app/fnReceiveDailySummaryReport" to="/app/fnReceiveDailySummaryReport/list" />
        {/* end report */}

        {/* ��§ҹ�������� */}
        <Route exact path="/app/chequeReport/list" component={ChequeReport} />
        <Redirect path="/app/chequeReport" to="/app/chequeReport/list" />
        {/* end report */}

        {/* RF014 ��§ҹ�������Թ�͹ */}
        <Route exact path="/app/payinReport/list" component={PayinReport} />
        <Redirect path="/app/payinReport" to="/app/payinReport/list" />
        {/* end report */}

        {/* RF015 ��§ҹ�����š�ê����Թ������ */}
        <Route exact path="/app/chequePayReport/list" component={ChequePayReport} />
        <Redirect path="/app/chequePayReport" to="/app/chequePayReport/list" />
        {/* end report */}

        {/* RF016 ��§ҹ�����š�ê����Թ�����Թ�͹ */}
        <Route exact path="/app/payinPayReport/list" component={PayinPayReport} />
        <Redirect path="/app/payinPayReport" to="/app/payinPayReport/list" />
        {/* end report */}

        {/* RF009 ��§ҹ��ýҡ�Թ */}
        <Route exact path="/app/trushMoneyReport/list" component={TrushMoneyReport} />
        <Redirect path="/app/trushMoneyReport" to="/app/trushMoneyReport/list" />
        {/* end report */}

        {/* RF011 ��§ҹ㺧ҹ��ҧ���� */}
        {/* <Route exact path="/app/worksheetPaymentReport/list" component={WorksheetPaymentReport} />
        <Redirect path="/app/worksheetPaymentReport" to="/app/worksheetPaymentReport/list" /> */}
        {/* end report */}


        {/* regex */}
        <Route exact path="/app/regex" component={Regex} />

        {/* other */}
        <Redirect path="/app" to="/app/dashboard" />
      </Switch>
    </AppLayout>
  );
};

export default AppRoute;
